<template>
    <div class="cards-view">
        <template v-for="i in Math.ceil(projects.length / 3)">
            <v-row :key="i" class="ma-0">
                <ProjectCard
                    v-for="(project, j) in projects.slice((i - 1) * 3, i * 3)"
                    :key="project.id"
                    :class="j === 1 ? 'mx-4' : ''"
                    :project="project"
                >
                </ProjectCard>
            </v-row>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProjectCard from './ProjectCard'
export default {
    name: 'CardsView',
    props: {
        projects: {
            type: Array,
            default: [],
        },
    },
    components: { ProjectCard },
    data: () => ({
        projectMenu: false,
        displayProjects: [],
    }),
    computed: {},
    methods: {
        addUser(projectId) {
            this.$parent.$emit('addUser', projectId)
        },
        deleteProject(projectId) {
            this.$parent.$emit('deleteProject', projectId)
        },
        renameProject(projectId) {
            this.$parent.$emit('renameProject', projectId)
        },
        changeProjectImage(projectId) {
            this.$parent.$emit('changeProjectImage', projectId)
        },
    },

    created() {
        this.$on('deleteProject', this.deleteProject)
        this.$on('addUser', this.addUser)
        this.$on('renameProject', this.renameProject)
        this.$on('changeProjectImage', this.changeProjectImage)
    },
    mounted() {},
}
</script>

<style scoped>
thead {
    background-color: #0000000b;
}
</style>
