<template>
    <div class="list-view">
        <v-data-table
            :headers="headers"
            :items="displayProjects"
            :hide-default-footer="hideDefaultFooter"
            :options="{ itemsPerPage: itemsPerPage }"
            v-model="selectedProjects"
            :show-select="hasOneOwnedProject"
            @input="selectProject($event)"
            @toggle-select-all="selectAllProjects"
            @click:row="goToProject($event)"
        >
            <template v-slot:[`item.owner`]="{ item }">
                <span>{{ item.owner.id === $auth.user.sub ? 'Я' : item.owner.name }}</span>
            </template>
            <template v-slot:[`item.available`]="{ item }">
                <v-avatar v-for="user in item.available" :key="user.id" size="36" class="mx-1 my-1">
                    <v-img transition="slide-x-transition" :src="user.avatar" :alt="user.deleteCount" />
                </v-avatar>
                <v-avatar color="#0000000b" v-if="item.avatarsDelete > 0" size="36" class="mx-2 my-2">
                    <span>+{{ item.avatarsDelete }}</span>
                </v-avatar>
            </template>
            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                <v-simple-checkbox
                    :value="item.id == !checkRole(item.id, 'project:delete') ? false : isSelected"
                    v-if="checkRole(item.id, 'project:delete')"
                    :ripple="false"
                    @input="select($event)"
                ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu :close-on-content-click="true" offset-x offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon medium v-bind="attrs" v-on="on" v-if="someActionsAvailable(item)">
                            <v-icon>mdi-dots-vertical</v-icon></v-btn
                        >
                    </template>

                    <v-card min-width="198px">
                        <v-list>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="addUser(item.id)"
                                :disabled="!checkAccess(item.id, 'owner')"
                            >
                                <span class="mx-3 my-2">Добавить пользователя</span>
                            </v-list-item>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="deleteProject(item.id)"
                                :disabled="!checkRole(item.id, 'project:delete')"
                            >
                                <span class="mx-3 my-2">Удалить проект</span>
                            </v-list-item>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="renameProject(item.id)"
                                :disabled="!checkRole(item.id, 'project:rename')"
                            >
                                <span class="mx-3 my-2">Переименовать</span>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { checkAccess } from '@/utils'
import { checkRole } from '@root/src/helpers/roles'
export default {
    name: 'ListViewTable',
    props: {
        projects: {
            type: Array,
            default: [],
        },
    },
    components: {},
    data: () => ({
        projectMenu: false,
        displayProjects: [],
        headers: [
            { text: 'Название проекта', value: 'name', sortable: false },

            { text: 'Доступен', value: 'available', sortable: false, width: '400px' },
            { text: '', value: 'actions', sortable: false },
        ],
    }),
    computed: {
        hideDefaultFooter() {
            return this.projects.length > 50 ? false : true
        },
        itemsPerPage() {
            return this.projects.length > 50 ? 15 : -1
        },
        selectedProjects: {
            get() {
                return this.$store.state.project.selectedProjects
            },
            set(value) {
                this.$store.commit('project/updateSelectedProjects', value)
            },
        },
        //Все проекты которые можно редактировать
        allProjectsWithAccess() {
            return this.projects.filter(el => this.checkRole(el.id, 'project:delete'))
            //return this.projects
        },
        hasOneOwnedProject() {
            return this.allProjectsWithAccess.length > 0
        },
    },
    watch: {
        projects() {
            this.setDisplayProjects()
        },
    },
    methods: {
        checkAccess,
        checkRole,
        setDisplayProjects() {
            function calculateAvatarsCount() {
                const oneAvatarWidth = 36 + 4 + 4
                const count = ((400 - oneAvatarWidth) / oneAvatarWidth).toFixed(0) - 1
                return count
            }

            const targetAvatarsCount = calculateAvatarsCount()
            const displayProjects = this.projects.map(proj => {
                const availableClone = JSON.parse(JSON.stringify(proj.users.filter(user => !user.isPending)))

                const startAvatarCount = availableClone.length
                let deleteCount = 0
                if (startAvatarCount > targetAvatarsCount) {
                    deleteCount = startAvatarCount - targetAvatarsCount
                    for (let i = 0; i < deleteCount; i++) {
                        availableClone.pop()
                    }
                }
                return {
                    id: proj.id,
                    name: proj.name,
                    owner: proj.owner,
                    available: availableClone,
                    avatarsDelete: deleteCount,
                    isOnBoardingActive: proj.isOnBoardingActive,
                }
            })
            this.displayProjects = displayProjects
        },
        someActionsAvailable(project) {
            return (
                this.checkAccess(project.id, 'owner') ||
                checkRole(project.id, 'project:delete') ||
                checkRole(project.id, 'project:rename')
            )
        },
        selectProject(e) {
            let selectedItems = [...e].filter(el => this.checkAccess(el.id, 'owner'))
            this.selectedProjects = selectedItems
        },
        selectAllProjects(e) {
            if (e.value === false) {
                const projectIdSet = new Set()
                e.items.forEach(el => {
                    projectIdSet.add(el.id)
                })
                this.selectedProjects = this.selectedProjects.filter(el => !projectIdSet.has(el.id))
                return
            }
            const selected = e.items
            let selectedItems = [...selected].filter(el => this.checkAccess(el.id, 'owner'))
            if (this.selectedProjects.length === this.allProjectsWithAccess.length) {
                this.selectedProjects = []
            } else {
                this.selectedProjects = selectedItems
            }
        },
        goToProject(project) {
            const onboardingActive = project.isOnBoardingActive
            if (onboardingActive) this.$router.push(`/project/${project.id}/onBoarding`)
            else this.$router.push(`/project/${project.id}`)
        },
        addUser(projectId) {
            this.$parent.$emit('addUser', projectId)
        },
        deleteProject(projectId) {
            this.$parent.$emit('deleteProject', projectId)
        },
        renameProject(projectId) {
            this.$parent.$emit('renameProject', projectId)
        },
    },

    created() {},
    mounted() {
        this.setDisplayProjects()
    },
}
</script>

<style lang="sass" scoped>
.navigation
    cursor: pointer

.list-view
    swidth: 1016px
    width: 100%

.v-list-item
    padding: 0 !important
    min-height: 0px

button:focus
    outline: 0
</style>
